$(function() {
  setTimeout('$(".flash").fadeOut("slow")', 3500); // flashフェードアウト
  initializePopoversAndTooltips(); // popoverとtooltipを初期化
  hidePopoversAndTooltipsAsScroll(); // スクロール時にpopoverとtooltipを非表示
  $("main").on("scroll", function() { $("footer").css("left", `-${ $(this).scrollLeft() }px`) }); // フッタースクロール
});

// MCモーダル
$(document).on("click", '[data-close="popup"]', function() {
  $("#popup").empty();
});

// loading表示
$(window).on("load", function() {
  $(".loader").hide();
});

$(document).on("click", "a.loading", function() {
  $(".loader").show();
});

$(document).on("submit", "form.loading", function() {
  $(".loader").show();
});

window.addEventListener("beforeunload", function() {
  $(".loader").show();
});

// エラーメッセージリセット
$(document).on("click", ".reset-errors", function() {
  $(".error-msg").remove();
  $(".is-invalid").removeClass("is-invalid");
  $("ul.invalid-feedbacks").empty();
});

// フォーム自動送信
$(document).on("change", ".auto-sbmt", function() {
  $(this).closest("form").attr("data-remote") === "true" ? Rails.fire($(this).closest("form")[0], "submit") : $(this).closest("form").submit()
});

// フォームの入力値の変更を取得
$(document).on("change", "[data-init-val]", function() {
  $(this).toggleClass("changed", $(this).val() != $(this).data("init-val"));
});

// フォームの入力値を初期化
$(document).on("click", "[data-reset-val]", function() {
  $(this).next("input").val($(this).data("reset-val"));
});

// クリップボードにコピー
$(document).on("click", "[data-copy]", function() {
  navigator.clipboard.writeText($(`[data-copied="${ $(this).attr("data-copy") }"]:first`).text());
});

// popoverとtooltipを初期化
window.initializePopoversAndTooltips = function () {
  var myDefaultAllowList = bootstrap.Tooltip.Default.allowList;
  myDefaultAllowList.strong = ["style"];
  [].slice.call($('[data-bs-toggle="popover"]')).map(function (popoverTriggerEl) {
    return new bootstrap.Popover(popoverTriggerEl, { html: true });
  });
  [].slice.call($('[data-bs-toggle="tooltip"]')).map(function (tooltipTriggerEl) {
    return new bootstrap.Tooltip(tooltipTriggerEl);
  });
}

// popoverとtooltipを非表示
window.hidePopoversAndTooltipsAsScroll = function () {
  $("*").on("scroll", function() {
    [].slice.call($(this).find('[data-bs-toggle="popover"]')).map(function (popoverTriggerEl) {
      bootstrap.Popover.getOrCreateInstance(popoverTriggerEl).hide();
    });
    [].slice.call($(this).find('[data-bs-toggle="tooltip"]')).map(function (tooltipTriggerEl) {
      bootstrap.Tooltip.getOrCreateInstance(tooltipTriggerEl).hide();
    });
  });
}
