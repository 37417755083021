// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "bootstrap"
import "jquery-ui/ui/widget"
import "jquery-ui/ui/widgets/datepicker"
import "jquery-ui/ui/widgets/draggable"
import "jquery-ui/ui/widgets/sortable"
import "@fortawesome/fontawesome-free/js/all"
import "../stylesheets/application"
import "./custom"
import "./employees"
import "./staffs"

var jQuery = require("jquery");
global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;

var bootstrap = require("bootstrap");
global.bootstrap = bootstrap;
window.bootstrap = bootstrap;

window.Cookies = require("js-cookie");

var rails = require("@rails/ujs");
global.Rails = rails;
window.Rails = rails;

Rails.start()
ActiveStorage.start()
