import consumer from "./consumer"

$(function() {
  if($("#shiftboard").length) {
    var matterId = $("body").attr("data-matter-id");
    const matterRoom = consumer.subscriptions.create({ channel: "MatterChannel", room: matterId }, {
      connected() {
        // Called when the subscription is ready for use on the server
      },

      disconnected() {
        // Called when the subscription has been terminated by the server
      },

      received(data) {
        if(data["type"] === "toast") {
          $(".toast-container").append(data["toast"]);
          var toast = new bootstrap.Toast($("#toast-" + data["toast_id"]));
          toast.show();
        } else if(data["type"] === "upd_att") {
          $(`[data-shift-icon-id="${ data["shift_id"] }"`).html(data["icon"]);
          var appliedCount = data["applied_cnt"];
          $(data["daily_applied"]).html(appliedCount);
        } else if(data["type"] === "applying") {
          $(data["applied_dom_id"]).html(data["latest_applied"]);
          $(data["comment_icon"]).toggleClass("d-none", !data["has_comment"]);
        } else if(data["type"] === "emailed") {
          $(data["unsent_badge"]).empty();
        }
      }

    });
  }
});
