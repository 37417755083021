import consumer from "./consumer"

$(function() {
  if($("body").attr("data-staff-id")) {
    var staffId = $("body").attr("data-staff-id");
    const staffRoom = consumer.subscriptions.create({ channel: "StaffChannel", room: staffId }, {
      connected() {
        // Called when the subscription is ready for use on the server
      },

      disconnected() {
        // Called when the subscription has been terminated by the server
      },

      received(data) {
        if(data["type"] === "update_calendar" && $("#staff-shifts-calendar").length) {
          $.each(data["daily_events"], function(i, data) {
            $(`[data-date="${ data[0] }"] [data-event]`).attr("data-event", data[1]);
          });
        } else if(data["type"] === "toast") {
          $(".toast-container").append(data["toast"]);
          var toastObj = $("#toast-" + data["toast_id"]);
          var toast = new bootstrap.Toast(toastObj);
          toast.show();
        } else if(data["type"] === "recruitments") {
          Rails.fire($("#recruitments")[0], "submit");
        }
      }

    });
  }
});
