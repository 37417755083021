// 追加募集取得
$(window).on("load", function() {
  if($("body").attr("data-staff-id")) {
    Rails.fire($("#recruitments")[0], "submit");
  }
});

// 案内資料を閉じる
$(document).on("click", ".list-group-item[data-document-id] .btn-close", function() {
  $(this).addClass("d-none").parent().next().empty();
});
