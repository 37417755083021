// チェックボックス一括選択
$(document).on("change", "[data-bulk-check]", function() {
  $(`[data-bulk-checked="${ $(this).data("bulk-check") }"]`).prop("checked", $(this).prop("checked"));
  var isDisabled = $(`[data-bulk-checked="${ $(this).data("bulk-check") }"]:checked`).length === 0;
  $(`[data-require-check="${ $(this).data("bulk-check") }"]`).prop("disabled", isDisabled);
});

// チェック入力必須フォーム送信
$(document).on("change", "[data-required-check]", function() {
  $(`[data-require-check="${ $(this).data("required-check") }"]`).prop("disabled", $(`[data-required-check="${ $(this).data("required-check") }"]:checked`).length === 0);
});

// 重複シフト制御
$(document).on("click", "#attendances-form .btn-outline-dark", function() {
  $("[data-duplicated]").removeAttr("data-duplicated").find(".duplicated").removeClass("duplicated");
});

// メールプレビュー
$(document).on("click", "#close-preview", function() {
  $(".popup-content").removeClass("d-none");
  $("#preview").remove();
});

// 自由記述メールの文字数カウント
$(document).on("keyup", "textarea#content", function() {
  $("#content-length").html($(this).val().length);
});

// Excel出力後の制御
$(document).on("submit", "#export-excel", function() {
  var startMonitorExport = setInterval(function() {
    if(Cookies.get("exported")) {
      Cookies.remove("exported");
      $(".loader").hide();
      $("#popup").empty();
      clearInterval(startMonitorExport);
    }
  }, 1000);
});

// 案内資料アップロード
$(document).on("change", "#document_image", function () {
  $(".new-img").remove();
  if($(this).val()) {
    $(".existing-img").addClass("d-none");
    var file = this.files[0];
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      var image = this.result;
      if(file.type === "application/pdf") {
        $("#image").append(`<iframe class="w-100 h-100 p-3 z-1 new-img" src="${ image }"></iframe>`);
      } else if(["image/jpg", "image/jpeg", "image/png"].indexOf(file.type) != -1) {
        $("#image").append(`<img class="mw-100 mh-100 p-3 z-1 new-img" src="${ image }">`);
      }
    }
  } else {
    $(".existing-img").removeClass("d-none");
  }
});

// 自由記述メールの宛先検索
$(document).on("click", ".shift-opt .btn-dark", function() {
  var cloned = $(this).parent().clone();
  cloned.find("select").val("");
  cloned.find("span:first-child").html("または");
  $(".shift-opt:last").after(cloned);
  $(".shift-opt").length >= 10 && $(".shift-opt .btn-dark").addClass("disabled")
  $(".shift-opt").length > 1 && $(".shift-opt .btn-danger").removeClass("disabled")
});

$(document).on("click", ".shift-opt .btn-danger", function() {
  $(this).parent().remove();
  $(".shift-opt:first span:first-child").html("条件　");
  $(".shift-opt").length < 10 && $(".shift-opt .btn-dark").removeClass("disabled")
  $(".shift-opt").length <= 1 && $(".shift-opt .btn-danger").addClass("disabled")
});

// 勤務時間編集
$(document).on("click", "#worktime-form .btn-outline-dark", function() {
  $("#worktime-form").removeAttr("data-duplicated").find("#chk_dup").val(1);
});

// シフト詳細開閉
$(document).on("click", "tbody[data-attendance-id] a.toggle-details:not(.collapsed)", function(e) {
  $(this).addClass("collapsed loading").closest("tbody").find("tr.details").remove();
  e.preventDefault();
  e.stopImmediatePropagation();
});

// 追加募集作成
$(document).on("click", "#new-rct-table [data-daily-status-id]", function(e) {
  var dailyStatusId = $(this).attr("data-daily-status-id");
  if($(this).toggleClass("selected").hasClass("selected")) {
    var date = new Date(dailyStatusId.slice(-10));
    var shift = $("#selected-for-rct li:first").clone().removeClass("d-none").attr("data-selected-daily-status-id", dailyStatusId);
    var icon = $(this).closest("tr").find("th:first > *:first").clone();
    shift.find("input").val($(this).find("[data-daily-setting-id]").attr("data-daily-setting-id")).prop("checked", true).after(icon);
    shift.find("span").html(`${ date.getDate() }（${ ["日", "月", "火", "水", "木", "金", "土"][date.getDay()] }）`);
    $("#selected-for-rct").append(shift);
  } else {
    $(`#selected-for-rct [data-selected-daily-status-id="${ dailyStatusId }"]`).remove();
  }
  e.preventDefault();
  e.stopImmediatePropagation();
});

$(document).on("click", '[data-unselect="rct"]', function() {
  $(`#new-rct-table [data-daily-status-id="${ $(this).closest("li").attr("data-selected-daily-status-id") }"]`).removeClass("selected");
  $(this).closest("li").remove();
});

// 自由記述メール破棄確認
$(document).on("click", '#shiftboard a:not(.link-light), #shiftboard tr[data-matter-staff-id] button[type="submit"], footer input[type="submit"]:not(.w-50)', function(e) {
  if($("#matter-staffs-email-popup").length && ($("input#subject").val() || $("textarea#content").val() || $('input[data-bulk-checked="add"]:checked').length)) {
    if(!confirm("入力内容を破棄してよろしいですか？")) {
      e.preventDefault();
      e.stopImmediatePropagation();
    }
  }
});

// シフト編集モーダルopen
$(document).on("click", '#shiftboard a[data-remote="true"]', function() {
  $(".loader").show();
  $('#shiftboard a[data-remote="true"]').addClass("pe-none");
});

// シフトボードの勤務状況検索
$(document).on("submit", "#work-conditions + form", function(e) {
  var rows = $("#shiftboard > table > tbody > tr[data-staff-id]");
  if($("#six_works_week").val()) { var rows = rows.has(`[data-6workdays-week="${ $("#six_works_week").val() }"]`) }
  if($("#work_40h_week").val()) { var rows = rows.has(`[data-work-40h-week="${ $("#work_40h_week").val() }"]`) }
  if($("#work_80h_month").val()) { var rows = rows.has(`[data-work-80h-month="${ $("#work_80h_month").val() }"]`) }
  $("#shiftboard > table > tbody > tr[data-staff-id]").attr("data-disp", false);
  rows.removeAttr("data-disp");
  $("#summaries-cnt").html($('#shiftboard > table > tbody > tr[data-staff-id]:not(.d-none):not([data-disp="false"])').length);
  var dropdown = new bootstrap.Dropdown($("#work-conditions"));
  dropdown.hide();
  e.preventDefault();
  e.stopImmediatePropagation();
});

// 下書一括確定後の操作制限
$(document).on("submit", "#attendances-drafted-popup form", function() {
  $("#board-disp-menu label").addClass("pe-none text-secondary");
});

// 勤務管理excel出力
$(document).on("submit", "#new-work-managements", function() {
  $("#work-manage-excel-popup").find('select, input[name="draft"], input[name="matters"]').attr("form", "new-work-managements");
});
