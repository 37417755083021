import consumer from "./consumer"

$(function() {
  if($("body").attr("data-employee-id")) {
    var employeeId = $("body").attr("data-employee-id");
    const employeeRoom = consumer.subscriptions.create({ channel: "EmployeeChannel", room: employeeId }, {
      connected() {
        // Called when the subscription is ready for use on the server
      },

      disconnected() {
        // Called when the subscription has been terminated by the server
      },

      received(data) {
        if(data["type"] === "toast") {
          $(".toast-container").append(data["toast"]);
          var toast = new bootstrap.Toast($("#toast-" + data["toast_id"]));
          toast.show();
        }
      }

    });
  }
});
